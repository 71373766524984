/* eslint-disable react/no-danger */
import { parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Segment, Header, Grid } from 'semantic-ui-react';
import keyBy from 'lodash/keyBy';
import { CmsBlocks } from '../cms/CmsScreen';
import { useScreenConfig } from '../config/screens.context';
import store from '../shared/Store';
import { fetchAppointments, fetchTimeSlots } from '../store/actions';
import './Appointments.scss';
import { generateSlots } from './components/Slots/slotUtils';
import { StepConfirmContent, StepConfirmSidebar } from './steps/Confirm';
import { StepPrepareContent, StepPrepareSidebar } from './steps/Prepare';
import { StepSelectSlotContent, StepSelectSlotSidebar } from './steps/SelectSlot';
import { StepContext } from './steps/StepContext';
import { ensureDate } from '../utils/date';
import { cancelAppointment } from '../sponsors/blocks/BookAppointmentBlock/utils';
import { useTracking } from '../Context';
import { bookAppointment } from './utils';

export function useFreeTimeSlots(collection, userId) {
  useEffect(() => {
    store.reduxStore.dispatch(fetchTimeSlots(collection, userId));
  }, [collection, userId]);
  return (
    useSelector((state) => state.timeSlots.timeSlots[userId]) || {
      isBookingAvailable: false,
      freeTimeSlots: [],
    }
  );
}

function computeTimeSlots(startDate, endDate, duration, freeTimeSlots) {
  const slotsByTime = keyBy(freeTimeSlots, ({ startDate: sDate }) => parseISO(sDate).getTime());
  function isSlotFree(slotDate) {
    const time = ensureDate(slotDate).getTime();
    return !!slotsByTime[time];
  }
  return generateSlots(startDate, endDate, duration, isSlotFree);
}

export function useSponsorAppointment(collection, userId) {
  useEffect(() => {
    store.reduxStore.dispatch(fetchAppointments());
  }, [collection, userId]);
  return useSelector((state) =>
    state.appointments.appointments.find((appointment) => appointment.groupId === userId),
  );
}

const steps = {
  'select-slot': {
    Sidebar: StepSelectSlotSidebar,
    Content: StepSelectSlotContent,
  },
  confirm: {
    Sidebar: StepConfirmSidebar,
    Content: StepConfirmContent,
  },
  prepare: {
    Sidebar: StepPrepareSidebar,
    Content: StepPrepareContent,
  },
};

const Main = () => {
  const { main = {}, days = [], appointmentUserRef = {} } = useScreenConfig('appointments');
  const { header, details } = main;
  // const [step, setStep] = useState('prepare');
  const [step, setStep] = useState('select-slot');
  const [confirmData, setConfirmData] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { trackEvent } = useTracking();

  const currentAppointment = useSponsorAppointment(
    appointmentUserRef.collection,
    appointmentUserRef._id,
  );
  // userId remplacé par groupId car platfom front s'attend a un groupID
  const timeSlots = useFreeTimeSlots(appointmentUserRef.collection, appointmentUserRef._id);
  const { freeTimeSlots } = timeSlots;

  useEffect(() => {
    setStep(currentAppointment ? 'prepare' : 'select-slot');
  }, [currentAppointment?._id]);

  function handleStep1Select(slot) {
    setSelectedSlot(slot);
    setStep('confirm');
  }

  async function handleConfirm(data) {
    const { startDate, endDate } = selectedSlot;
    const appointmentToBook = {
      startDate,
      endDate,
      groupId: appointmentUserRef._id,
      message: data.message,
    };

    await bookAppointment(appointmentUserRef, appointmentToBook, {
      setStep,
      previousAppointment: currentAppointment,
    });

    setConfirmData(data);
  }

  async function handleCancel() {
    if (!currentAppointment) return;
    cancelAppointment(currentAppointment, appointmentUserRef, { trackEvent });
  }

  function handleUpdate() {
    setStep('select-slot');
  }

  const daysWithSlots = days.map((day) => ({
    ...day,
    daySlots: day.halfDays.map((grp) => ({
      ...grp,
      slots: computeTimeSlots(grp.startDate, grp.endDate, 15, freeTimeSlots),
    })),
  }));

  const { Sidebar, Content } = steps[step] || {};

  return (
    <Segment>
      {header && <Header as="h1">{header}</Header>}
      {details && <div dangerouslySetInnerHTML={{ __html: details }} />}
      <StepContext.Provider
        value={{
          days: daysWithSlots,
          onSelect: handleStep1Select,
          onConfirm: handleConfirm,
          onCancel: handleCancel,
          onUpdate: handleUpdate,
          appointmentUserRef,
          setStep,
          freeTimeSlots,
          selectedSlot,
          confirmData,
          currentAppointment,
        }}
      >
        <Grid stackable style={{ marginTop: '1em', marginBottom: '1em' }}>
          <Grid.Column width={4}>{Sidebar && <Sidebar />}</Grid.Column>
          <Grid.Column width={12}>{Content && <Content />}</Grid.Column>
        </Grid>
      </StepContext.Provider>
    </Segment>
  );
};

const Appointments = () => {
  const { header, footer } = useScreenConfig('appointments');
  return (
    <div className="appointments--screen" style={{ marginTop: 14 }}>
      {header && <CmsBlocks blocks={header.blocks} />}
      <Main />
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </div>
  );
};

Appointments.propTypes = {};

export default Appointments;
