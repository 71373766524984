/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';
import { useConfig } from '../../config/config.context';
import { useDesignConfig } from '../../config/design.context';
import './footer.scss';
import CdnImage from '../CdnImage';
import FooterModal from './FooterModal';
import FooterLink from './FooterLink';
import CookieModal from './CookieModal';

function Footer(props) {
  const { layout } = useDesignConfig();
  const { footer } = useConfig();
  if (!footer || layout === 'accordion') return null;
  const { blocks = [] } = footer;
  const { style } = props;

  const renderBlock = (block) => {
    const { key = '', type } = block;
    switch (type) {
      case 'link':
        return <FooterLink {...block} />;
      case 'html': {
        const { content } = block;
        return (
          <div className={cx('footer--text', key)} dangerouslySetInnerHTML={{ __html: content }} />
        );
      }
      case 'image': {
        const { image } = block;
        if (!image || !image.uri) return null;
        return <CdnImage src={image} className="footer--logo" alt="footer-logo" maxHeight={50} />;
      }
      case 'social': {
        const { items = [] } = block;
        return (
          <div className="footer--socialmedia">
            {items.map(({ icon, url }) => (
              <a href={url} target="_blank" rel="noreferrer">
                <Icon name={icon} size="big" />
              </a>
            ))}
          </div>
        );
      }
      case 'modal': {
        const { link, ...rest } = block;
        return <FooterModal {...rest} trigger={<FooterLink {...link} />} />;
      }
      case 'cookieModal': {
        return <CookieModal {...block} />;
      }
      default:
        return null;
    }
  };
  return (
    <footer className="footer" style={style}>
      <div className="footer--container">{blocks.map(renderBlock)}</div>
    </footer>
  );
}

Footer.defaultProps = {
  style: {},
};

Footer.propTypes = {
  style: PropTypes.object,
};

export default Footer;
