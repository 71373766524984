import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { useSetState } from 'ahooks';
import { Modal, Form, TextArea, Icon, Button, Header, Input } from 'semantic-ui-react';
import { useMe } from '../profile/hooks';
import ImageUploader from '../components/ImageUploader';
import { getString } from '../utils';
import { sweetConfirmAlert } from '../utils/popupUtils';
import socialWallService from '../core/services/social-wall.service';
import { showBlacklistError } from './socialWallUtils';
import SquareImage from '../components/SquareImage';

const translationPrefix = 'social-wall';

function hasChanged(prevPost, newPost) {
  if (!prevPost) {
    const { image, message } = newPost;
    return image || !!message;
  }
  return !isEqual(pick(prevPost, ['image', 'message', 'location']), newPost);
}

async function putPost(user, post, prevPost) {
  if (prevPost) {
    return socialWallService.patchMessage(post.id, post);
  }

  return socialWallService.postMessage({
    ...post,
    userId: user._id,
    thumbnail: user.thumbnail,
    firstName: user.firstName,
    lastName: user.lastName,
  });
}

function NewSocialWallPostModal({ onClose, post: prevPost }) {
  const [post, updatePost] = useSetState(() => prevPost || {});
  const [showInput, setShowInput] = useState(false);
  const me = useMe();
  const { firstName, lastName, thumbnail } = me;
  const onCancel = async () => {
    if (hasChanged(prevPost, post)) {
      const response = await sweetConfirmAlert({
        title: getString(`${translationPrefix}.cancel-without-posting-message`),
      });
      if (!response) return;
    }
    onClose();
  };
  const onPost = async () => {
    const res = putPost(me, post, prevPost);
    if (res.errors) {
      showBlacklistError(res, post.message);
    } else onClose();
  };
  return (
    <Modal className="SociaWall--Modal" size="large" open onClose={onClose} closeIcon>
      <Modal.Content scrolling className="SocialWall--Modal__Content">
        <div className="SocialWall--Modal__Content--Columns">
          <div className="SocialWall--Modal__Content--Image">
            <ImageUploader
              field="image"
              onChange={(image) => updatePost({ image })}
              placeholder={getString(`${translationPrefix}.upload-image`)}
              size="big"
              style={{ height: '100%', width: '100%' }}
              value={post.image || ''}
            />
          </div>
          <div className="SocialWall--Modal__Content--Message">
            <Header as="h4" className="user">
              <SquareImage src={thumbnail} imageSize={32} circular style={{ width: 32 }} />
              {firstName} {lastName}
            </Header>
            <Button className="location" onClick={() => setShowInput(!showInput)}>
              <Icon name="pin" /> {getString(`${translationPrefix}.add-location`)}
            </Button>
            <Form style={{ flex: 1 }}>
              {showInput && (
                <Input
                  className="location--input"
                  icon="pin"
                  iconPosition="left"
                  placeholder={getString(`${translationPrefix}.type-location`)}
                  value={post.location || ''}
                  onChange={(e) => updatePost({ location: e.target.value })}
                />
              )}
              <TextArea
                className="message"
                placeholder={getString(`${translationPrefix}.add-content`)}
                value={post.message || ''}
                onChange={(e) => updatePost({ message: e.target.value })}
              />
            </Form>
            <div>
              <Button onClick={onPost} color="black" floated="right">
                <Icon name="send" />
                {getString(`${translationPrefix}.post-message`)}
              </Button>
              <Button onClick={onCancel} floated="right">
                {getString(`${translationPrefix}.cancel`)}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

NewSocialWallPostModal.defaultProps = {
  post: undefined,
};

NewSocialWallPostModal.propTypes = {
  post: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default NewSocialWallPostModal;
