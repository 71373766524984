import keyBy from 'lodash/keyBy';
import { updateOrAppendItem } from '../../utils/arrayUtils';
import { SET_ALL, SET_BY_ID } from './sponsors.types';

const initialState = {
  sponsors: [],
  sponsorsById: {},
};

export default function reduceSponsors(state = initialState, action) {
  switch (action.type) {
    case SET_ALL: {
      return { sponsors: action.sponsors, sponsorsById: keyBy(action.sponsors, '_id') };
    }
    case SET_BY_ID: {
      // Update a given sponsor
      const sponsors = updateOrAppendItem(state.sponsors, action.sponsor);
      return { sponsors, sponsorsById: keyBy(sponsors, '_id') };
    }
    default:
      return state;
  }
}
