import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import platformService from '../../core/services/platform.service';
import store from '../../shared/Store';
import { useStoreCollectionSync } from '../../utils/useVersionSync';
import { throttledFetchWorkshops } from './workshops.actions';
import { selectWorkshopsById, selectWorkshopsBySlug } from './workshops.selectors';

export function useWorkshop({ workshopId, workshopSlug, match }) {
  const workshopsById = useSelector(selectWorkshopsById) || {};
  const workshopsBySlug = useSelector(selectWorkshopsBySlug) || {};

  if (workshopId) return workshopsById[workshopId];
  const slug = workshopSlug || match?.params?.slug;
  if (slug) return workshopsBySlug[slug];

  return undefined;
}

export function useWorkshopsFromIds(workshopIds) {
  const workshopsById = useSelector(selectWorkshopsById);
  return useMemo(() => {
    if (!workshopIds) return null;
    return workshopIds.map((id) => workshopsById[id]).filter((v) => v);
  }, [workshopsById, workshopIds]);
}

export function useSyncedWorkshops() {
  useStoreCollectionSync({ resourceType: 'workshops', fetcher: throttledFetchWorkshops });
  return useSelector((state) => state.workshops.workshops);
}

export function useSyncedCollectionWorkshops(collection) {
  useStoreCollectionSync({ resourceType: 'workshops', fetcher: throttledFetchWorkshops });
  return useSelector((state) =>
    state.workshops.workshops.filter((w) => w.collection === collection),
  );
}

export function useFeedbackUrl(workshop, feedback) {
  const [feedbackUrl, setFeedbackUrl] = useState(null);
  const { uri, title } = feedback || {};
  useEffect(() => {
    async function check() {
      if (!uri) return;
      const api = `${uri.replace('/html', '/api')}/workshops/${workshop._id}/users/${store.userId}`;
      const res = await platformService.secureGet(api);
      if (res) {
        const { status, token } = res;
        if (status === 'display') {
          // Generate workshop-specific uri
          setFeedbackUrl(`${uri}/workshops/${workshop._id}?token=${token}&userId=${store.userId}`);
        }
      }
    }
    check();
  }, [workshop, feedback]);
  return { feedbackUrl, title };
}
