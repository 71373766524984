import PropTypes from 'prop-types';
import React from 'react';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import CdnImage from '../components/CdnImage';
import ExpiringNavLink from '../components/ExpiringNavLink';
import Footer from '../components/Footer';
import PageBackground from '../components/PageBackground';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import Styles from '../Styles';
import { getString } from '../utils';
import './authentication.scss';

const translationPrefix = 'login';

const FullScreenContainer = ({ children }) => (
  <div className="fullscreen-container">
    <div className="fullscreen-center-wrapper">
      <Grid textAlign="center">
        <Grid.Column>{children}</Grid.Column>
      </Grid>
    </div>
    <Footer />
  </div>
);
FullScreenContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

const LoginBanner = () => {
  const design = useDesignConfig();
  const { banner } = design;
  // eslint-disable-next-line no-undef
  if (!banner) return null;
  return (
    <div className="LoginBanner">
      <a href="/" target="_blank">
        <CdnImage src={banner} alt="salon-banner" />
      </a>
    </div>
  );
};
const LoginFooter = () => {
  const design = useDesignConfig();
  const { loginFooter } = design;

  if (!loginFooter) return null;
  return <CdnImage className="LoginFooter" src={loginFooter} />;
};

const LoginLayout = ({ children, error }) => {
  const { loginBackground, loginLogo, loginLogoProps, primaryColor: color } = useDesignConfig();
  const config = useConfig();
  const { isPlatformClosed } = config;
  return (
    <>
      <Styles />
      <PageBackground image={loginBackground} />
      <FullScreenContainer>
        {loginLogo && (
          <ExpiringNavLink to="/">
            <CdnImage
              className="authentication--logo"
              as="img"
              src={loginLogo}
              maxWidth={422}
              maxHeight={100}
              alt="event logo"
              {...loginLogoProps}
            />
          </ExpiringNavLink>
        )}
        <p className="authentication--welcome">
          {getString(`${translationPrefix}.welcome-to-the-platform`)}
        </p>
        <Form size="large" error={!!error}>
          <Segment className="authentication--container">
            <LoginBanner />
            {isPlatformClosed && (
              <div className="platform-closed">
                <Header as="h2" style={{ color }}>
                  {getString(`${translationPrefix}.platform-closed-title`)}
                </Header>
                <p>{getString(`${translationPrefix}.platform-closed-content`)}</p>
              </div>
            )}
            {!isPlatformClosed && children}
          </Segment>
        </Form>
        <LoginFooter />
      </FullScreenContainer>
    </>
  );
};

LoginLayout.defaultProps = {
  error: false,
};
LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
  error: PropTypes.bool,
};

export default LoginLayout;
