import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import { areIntervalsOverlapping, parseISO, formatISO, startOfDay } from 'date-fns';

export function findOverlappingEvent(events, selectedEvent) {
  if (!selectedEvent.startDate || !selectedEvent.endDate) return false;
  const rightInterval = {
    start: parseISO(selectedEvent.startDate),
    end: parseISO(selectedEvent.endDate),
  };
  return events.find((event) => {
    const leftInterval = {
      start: parseISO(event.startDate),
      end: parseISO(event.endDate),
    };
    try {
      return areIntervalsOverlapping(leftInterval, rightInterval);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e, leftInterval, rightInterval);
      return false;
    }
  });
}
export function checkUserAvailability(events, selectedEvent) {
  return findOverlappingEvent(events, selectedEvent) == null;
}

export function groupEventsByDays(events) {
  const eventsByDay = groupBy(events, (event) =>
    formatISO(startOfDay(parseISO(event.startDate)), { representation: 'date' }),
  );
  const days = keys(eventsByDay).sort();
  return { days, eventsByDay };
}
