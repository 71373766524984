import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import flocklerUtils from './flocklerUtils';

const Flockler = ({ projectId, feedId }) => {
  useEffect(() => {
    const lazySub = flocklerUtils.initialize(projectId, feedId);

    return () => {
      lazySub.then((unsub) => unsub());
    };
  }, [projectId, feedId]);
  return <div id={flocklerUtils.domIdFromFeedId(feedId)} />;
};
Flockler.propTypes = {
  projectId: PropTypes.string.isRequired,
  feedId: PropTypes.string.isRequired,
};

export default Flockler;
