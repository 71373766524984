import { toast } from 'react-toastify';
import platformService from '../core/services/platform.service';
import store from '../shared/Store';
import { showConfirm, showError } from '../sponsors/blocks/BookAppointmentBlock/utils';
import { fetchAppointments, fetchTimeSlots } from '../store/actions';
import { getString } from '../utils';
import { getTimeZone, localeFormat } from '../utils/date';

const { timeZone: timezone } = getTimeZone() || {};

const translationPrefix = 'appointments.steps';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

export function formatAppointmentDate(appointment) {
  return localeFormat(appointment.startDate, 'PPPPp');
}

export async function bookAppointment(
  appointmentUserRef,
  appointmentToBook,
  { setStep, previousAppointment },
) {
  if (previousAppointment) {
    // We are changing a previous appointment !!
    const confirmed = await showConfirm(
      t('confirm.confirm-replace-title'),
      t('confirm.confirm-replace-message', {
        initialDate: formatAppointmentDate(previousAppointment),
        newDate: formatAppointmentDate(appointmentToBook),
      }),
    );
    if (!confirmed) {
      setStep('prepare');
      return; // Don't change anything
    }
  }

  const res = await platformService.bookAppointment(appointmentUserRef.collection, {
    ...appointmentToBook,
    appointmentToReplace: previousAppointment?._id,
    timezone,
  });

  if (res.success) {
    toast(
      getString(`${translationPrefix}.prepare.side-note`, {
        date: localeFormat(appointmentToBook.startDate, 'PPPPp'),
      }),
    );
  }

  // error management
  else if (res.errors) {
    const { code } = res.errors[0];
    if (code === 'E_NO_AVAILABLE_TIMESLOT') {
      showError(code);
    } else {
      showError('ERROR');
    }
    setStep('select-slot');
  }

  // Refresh data...
  store.reduxStore.dispatch(fetchAppointments()); // refresh user appointments
  store.reduxStore.dispatch(fetchTimeSlots(appointmentUserRef.collection, appointmentUserRef._id)); // refresh slots
}
