import groupBy from 'lodash/groupBy';
import { format, parseISO } from 'date-fns';
import { localeFormat } from './date';
import { getWorkshopStatus } from './dateUtils';

function toDate(value) {
  if (!value) return value;
  return format(parseISO(value), 'yyyy-MM-dd');
}

export function generateWorkshopStatus(workshops) {
  return (workshops || []).map((workshop) => ({
    ...workshop,
    workshopStatus: getWorkshopStatus(workshop),
  }));
}

export function generateGroups(data, groupByConfig) {
  const { field, type, dateFormat = 'PPPP', options } = groupByConfig;
  if (type === 'date' || field === 'startDate') {
    const groups = groupBy(data, (item) => {
      const value = item[field];
      if (!value) return '';
      return toDate(value);
    });

    return Object.keys(groups)
      .sort()
      .map((key) => ({
        id: key,
        label: key ? localeFormat(parseISO(key), dateFormat) : '',
        items: groups[key],
      }));
  }

  // TODO: order keys ?
  const groups = groupBy(data, field);

  if (options && options.length > 0) {
    // Manual order/label
    return options
      .map(({ value, label, ...rest }) => ({
        id: value,
        label,
        ...rest,
        items: groups[value] || [],
      }))
      .filter((opt) => opt.items.length > 0);
  }

  return Object.keys(groups).map((key) => ({
    id: key,
    label: key,
    items: groups[key],
  }));
}
