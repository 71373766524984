/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import CdnImage from '../CdnImage';
import './VideoCard.scss';
import VideoModal from './VideoModal';

const VideoCard = ({ onClick, video, style }) => {
  const [showModal, setShowModal] = useState(false);
  const { title, value = {}, thumbnail } = video;
  const { trackEvent } = useTracking();

  const { downloadUri } = value;

  function onDownload(e) {
    e.stopPropagation();
    // TODO: track download for stats :p
    trackEvent(eventTags.VIDEO_DOWNLOAD, { video });
  }

  function handleClick() {
    if (onClick) {
      onClick(video);
    } else {
      setShowModal(true);
    }
  }

  const videoTitle = title || value.originalFilename;
  return (
    <>
      <Card className="card--video" style={style} onClick={handleClick}>
        <div className="ui embed">
          <Icon name="video play" />
          {thumbnail?.uri && (
            <CdnImage className="placeholder" wrapped src={thumbnail} as="img" maxWidth={400} />
          )}
        </div>
        <Card.Content className="card--video-info">
          <div className="card--video-title u-singleline ">{videoTitle}</div>
          {downloadUri && (
            <Button
              circular
              basic
              icon="download"
              color="blue"
              as="a"
              target="_blank"
              href={downloadUri}
              onClick={onDownload}
              size="mini"
            />
          )}
        </Card.Content>
      </Card>

      {showModal && <VideoModal video={video} onClose={() => setShowModal(false)} />}
    </>
  );
};
VideoCard.defaultProps = {
  onClick: undefined,
  style: undefined,
};
VideoCard.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  video: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.shape({ uri: PropTypes.string.isRequired }).isRequired,
    thumbnail: PropTypes.shape({ uri: PropTypes.string.isRequired }),
  }).isRequired,
};

export default VideoCard;
