import React from 'react';
import { Redirect } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { useDesignConfig } from '../config/design.context';
import store from '../shared/Store';
import { getString } from '../utils';
import { markdownUrlify } from '../utils/stringUtils';
import LoginPasswordForm from './components/LoginPasswordForm';
import OneTimePasswordForm from './components/OneTimePasswordForm';
import { useForceUpdate, useLoginConfig } from './hooks';
import LoginLayout from './LoginLayout';

const translationPrefix = 'login';

const loginModes = {
  otp: OneTimePasswordForm,
  password: LoginPasswordForm,
};

const LoginScreen = () => {
  const design = useDesignConfig();
  const login = useLoginConfig();
  const forceUpdate = useForceUpdate();
  if (store.isLoggedIn()) {
    return <Redirect to="/" />;
  }
  const { primaryColor: color } = design;

  const notYetRegistered = getString(`${translationPrefix}.not-yet-registered`);
  const LoginComponent = loginModes[login.mode] || loginModes.password;
  return (
    <LoginLayout>
      <div className="platform-opened">
        <Header as="h2" style={{ color }} textAlign="center">
          {getString(`${translationPrefix}.connect-to-your-account`)}
        </Header>
        <LoginComponent onLogin={forceUpdate} />
        {notYetRegistered && (
          <p className="text--not-yet-registered">{markdownUrlify(notYetRegistered)}</p>
        )}
      </div>
    </LoginLayout>
  );
};

export default LoginScreen;
