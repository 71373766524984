import { isAfter, parseISO, startOfDay } from 'date-fns';

export function hasActiveLive(session) {
  const { liveStreams } = session;
  return liveStreams?.length > 0 && liveStreams[0].visible;
}

export function computeMidTime({ startDate, endDate }) {
  if (!startDate && !endDate) return null;
  const startAt = parseISO(startDate);
  const endAt = parseISO(endDate);
  return new Date((startAt.getTime() + endAt.getTime()) / 2);
}

export function upcomingLiveSession(sessions, now) {
  const nowDate = new Date(now);
  const today = startOfDay(nowDate);
  if (sessions?.length === 0) return null;
  // Today or later sessions
  const todayOrLaterSessions = sessions.filter(({ startDate }) =>
    isAfter(parseISO(startDate), today),
  );
  const candidateSessions = todayOrLaterSessions.filter(({ noLive }) => !noLive);
  // Find first session that has an active live
  const activeLive = candidateSessions.find(hasActiveLive);
  if (activeLive) return activeLive;

  // Didn't find any live. Find next "upcoming" session
  return candidateSessions.find((s) => isAfter(computeMidTime(s), nowDate));
}
