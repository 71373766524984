export const eventTags = {
  SPONSOR_APPOINTMENT_BOOK: 'SPONSOR_APPOINTMENT_BOOK',
  SPONSOR_APPOINTMENT_CANCEL: 'SPONSOR_APPOINTMENT_CANCEL',
  SPONSOR_APPOINTMENT_ADD_TO_CALENDAR: 'SPONSOR_APPOINTMENT_ADD_TO_CALENDAR',
  SPONSOR_DOWNLOAD_FILE: 'SPONSOR_DOWNLOAD_FILE',
  SPONSOR_GO_TO_PAGE: 'SPONSOR_GO_TO_PAGE',
  PROGRAM_APPOINTMENT_BOOK: 'PROGRAM_APPOINTMENT_BOOK',
  PROGRAM_APPOINTMENT_CANCEL: 'PROGRAM_APPOINTMENT_CANCEL',
  PROGRAM_APPOINTMENT_ADD_TO_CALENDAR: 'PROGRAM_APPOINTMENT_ADD_TO_CALENDAR',
  PROGRAM_DOWNLOAD_FILE: 'PROGRAM_DOWNLOAD_FILE',
  PROGRAM_GO_TO_PAGE: 'PROGRAM_GO_TO_PAGE',
  WORKSHOP_REGISTER: 'WORKSHOP_REGISTER',
  WORKSHOP_UNREGISTER: 'WORKSHOP_UNREGISTER',
  WORKSHOP_ADD_TO_CALENDAR: 'WORKSHOP_ADD_TO_CALENDAR',
  WORKSHOP_VIEW_LIVE: 'WORKSHOP_VIEW_LIVE',
  WORKSHOP_CLICK_LIVE: 'WORKSHOP_CLICK_LIVE',
  CTA_NETWORKING_ENABLE: 'CTA_NETWORKING_ENABLE',
  NETWORKING_INVITATION_ACCEPT: 'NETWORKING_INVITATION_ACCEPT',
  NETWORKING_INVITATION_IGNORE: 'NETWORKING_INVITATION_IGNORE',
  NETWORKING_CONNECT_TO_USER: 'NETWORKING_CONNECT_TO_USER',
  NETWORKING_ENABLE: 'NETWORKING_ENABLE',
  NETWORKING_DISABLE: 'NETWORKING_DISABLE',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  VIEW_FILE: 'VIEW_FILE',
  REPLAY_VIDEO_LAUNCHED: 'REPLAY_VIDEO_LAUNCHED',
  VIDEO_VIEW: 'VIDEO_VIEW',
  VIDEO_DOWNLOAD: 'VIDEO_DOWNLOAD',
  VIDEO_VIEW_TICK: 'VIDEO_VIEW_TICK',
};
