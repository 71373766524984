import cx from 'classnames';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Banner from '../../headers/blocks/Banner';
import CloudTvBlock from '../../home/blocks/CloudTvBlock';
import CountdownBlock from '../../home/blocks/CountdownBlock';
import VideoBlock from '../../home/blocks/VideoBlock';
import Blocks from '../Blocks';
import BrowserCheckBlock from './BrowserCheckBlock';
import ContactBlock from './ContactBlock';
import FilesBlock from './FilesBlock';
import HtmlBlock from './HtmlBlock';
import IframeBlock from './IframeBlock';
import ImageBlock from './ImageBlock';
import ReplayBlock from './ReplayBlock';
import VideosBlock from './VideosBlock';
import WorkshopFilesBlock from './WorkshopFilesBlock';

// eslint-disable-next-line no-use-before-define
const RowBlock = ({ _id, columns, blocks, className, ...props }) => (
  <Grid>
    <Grid.Row key={_id} className={className} stretched>
      <Blocks blocks={blocks} blockComponents={cmsFields} />
    </Grid.Row>
  </Grid>
);

const ColumnBlock = ({ _id, width, blocks, className, ...props }) => (
  <Grid.Column
    className={cx('cms-page--block', `column--block`)}
    width={width}
    style={{ flex: width ? undefined : 1 }}
  >
    <Blocks blocks={blocks} blockComponents={cmsFields} />
  </Grid.Column>
);

export const cmsFields = {
  banner: Banner,
  browserCheck: BrowserCheckBlock,
  cloudTv: CloudTvBlock,
  column: ColumnBlock,
  contact: ContactBlock,
  countdown: CountdownBlock,
  workshopFiles: WorkshopFilesBlock,
  files: FilesBlock,
  html: HtmlBlock,
  iframe: IframeBlock,
  image: ImageBlock,
  replay: ReplayBlock,
  row: RowBlock,
  video: VideoBlock,
  videos: VideosBlock,
};
