import PropTypes from 'prop-types';
import React from 'react';
import { Segment } from 'semantic-ui-react';
import CdnImage, { cdnImagePropTypes } from '../../components/CdnImage';
import OptLink from '../../OptLink';
import { imageProptypes } from '../../propTypes';

export const ClickableImage = ({ image, link, trackClick, ...props }) => (
  <OptLink to={link} trackClick={trackClick}>
    <CdnImage src={image} {...props} />
  </OptLink>
);

function Banner({ banner, image, link, trackClick, maxWidth, maxHeight, func }) {
  if (!banner && !image) return null;
  return (
    <Segment className="Home--banner">
      <ClickableImage
        link={link}
        trackClick={trackClick}
        image={image || banner}
        alt="banner"
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
        fluid
      />
    </Segment>
  );
}

Banner.defaultProps = {
  banner: undefined,
  image: undefined,
  func: undefined,
  link: undefined,
  maxHeight: undefined,
  maxWidth: 1127,
  trackClick: undefined,
};

Banner.propTypes = {
  banner: imageProptypes,
  image: imageProptypes,
  func: cdnImagePropTypes.func,
  link: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  trackClick: PropTypes.func,
};

export default Banner;
