import BookAppointmentBlock from './BookAppointmentBlock';
import ContactsBlock from './ContactsBlock';
import CTABlock from './CTABlock';
import FilesBlock from './FilesBlock';

export const sidebarBlockComponents = {
  bookAppointment: BookAppointmentBlock,
  files: FilesBlock,
  contacts: ContactsBlock,
  cta: CTABlock,
};
