import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { getString } from '../../utils';

function computeUserAgenda(workshops, agenda, sponsors, programs) {
  const { workshopsById } = workshops;
  const events = agenda.agenda
    .map((event) => {
      const { type } = event;
      switch (type) {
        case 'workshop':
          return { ...workshopsById[event._id], type: 'workshop' };

        case 'appointment': {
          const { groupId } = event;
          const sponsor = sponsors.sponsorsById[groupId] || programs.programsById[groupId] || {};
          return {
            ...event,
            title: getString(`appointments.slots.meeting-with`, { name: sponsor.name }),
            appointment: event,
            sponsor,
          };
        }
        default:
          return null;
      }
    })
    .filter((v) => v);
  return orderBy(events, 'startDate', 'asc');
}

export const userAgenda = createSelector(
  [
    (state) => state.workshops,
    (state) => state.agenda,
    (state) => state.sponsors,
    (state) => state.programs,
  ],
  computeUserAgenda,
);
