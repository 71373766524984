import { combineReducers } from 'redux';
import agenda from '../../agenda/store/agenda.reducers';
import users from '../../core/users/users.reducers';
import networking from '../../networking/store/networking.reducers';
import participants from '../../participants/store/participants.reducers';
import programs from '../../programs/store/programs.reducers';
import sponsors from '../../sponsors/store/sponsors.reducers';
import workshops from '../../workshops/store/workshops.reducers';
import appointments from './appointments';
import registrations from './registrations';
import timeSlots from './timeSlots';
import user from './user';

export default combineReducers({
  agenda,
  user,
  users,
  sponsors,
  workshops,
  registrations,
  timeSlots,
  appointments,
  programs,
  participants,
  networking,
});
