import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FloatingChat from '../../components/Chat/FloatingChat';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { useFirebase, useFirestoreCollection, useFirestoreDocument } from '../../utils/useFirebase';

const SponsorChat = ({ type, sponsorId, sponsor, translationPrefix }) => {
  const firebase = useFirebase();
  const self = useSelector((state) => state.user?.user);
  const chatRef = useMemo(
    () =>
      firebase?.firestore().collection('chats').doc(store.eventId).collection(type).doc(sponsorId),
    [firebase, type, sponsorId],
  );
  const messagesRef = useMemo(() => chatRef?.collection('messages'), [chatRef]);

  const { item: chat } = useFirestoreDocument(chatRef);
  const filter = useCallback((ref) => ref?.orderBy('createdAt', 'desc').limit(200), []);
  const { list: messages, create: addMessage, remove: removeMessage } = useFirestoreCollection(
    messagesRef,
    filter,
  );

  return !chat?.open ? null : (
    <FloatingChat
      title={getString(`${translationPrefix}.chat.title`, sponsor)}
      messages={messages}
      me={self?._id}
      sponsorImage={sponsor.logo}
      onDelete={(id) => removeMessage(id)}
      onSendMessage={async (text) => {
        await addMessage({
          text,
          createdAt: new Date().toISOString(),
          emitter: self._id,
          profile: {
            firstName: self.firstName,
            lastName: self.lastName,
            thumbnail: self.thumbnail,
            isSponsor: false,
          },
        });
      }}
    />
  );
};

SponsorChat.defaultProps = {
  sponsor: {},
  translationPrefix: 'sponsors.sponsor',
  type: 'sponsors',
};

SponsorChat.propTypes = {
  sponsor: PropTypes.shape({ logo: PropTypes.shape({ uri: PropTypes.string }) }),
  sponsorId: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string,
  type: PropTypes.string,
};

export default SponsorChat;
