import { useMount } from 'ahooks';
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Header, Message } from 'semantic-ui-react';
import { withConfig } from '../config/config.context';
import { withDesign } from '../config/design.context';
import useTranslations from '../hooks/useTranslations';
import store, { getParameterByName } from '../shared/Store';
import { getString } from '../utils';
import LoginLayout from './LoginLayout';

const translationPrefix = 'login';

export function normalizeString(string) {
  if (!string) return '';
  return string.trim();
}

export function checkPasswordValidity(password) {
  return !(!password || password.length < 8);
}

export function checkPasswordsSimilarity(password, confirmPassword) {
  return normalizeString(password) === normalizeString(confirmPassword);
}

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: false,
      token: getParameterByName('token'),
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ [name]: value, message: null, error: null });
  };

  // eslint-disable-next-line consistent-return
  handleResetPassword = async () => {
    const { password, confirmPassword, token } = this.state;
    const { config } = this.props;
    const autoLogin = config?.screens?.login?.resetPassword?.autoLogin;
    const stayConnected = config?.screens?.login?.stayConnected;
    const isValidPassword = checkPasswordValidity(password);
    const areSamePasswords = checkPasswordsSimilarity(password, confirmPassword);

    if (!isValidPassword) {
      this.setState({ error: getString(`${translationPrefix}.error-password-characters-length`) });
      return;
    }
    if (!areSamePasswords) {
      this.setState({ error: getString(`${translationPrefix}.error-password-not-same`) });
      return;
    }

    const res = await store.resetPassword(token, password, autoLogin, stayConnected);
    if (res.success) {
      this.setState({ success: true, error: null });
    } else {
      // FIXME: remove this if with check token validity before pressing reset password button
      this.setState({ error: getString(`${translationPrefix}.link-has-expired`) });
    }
  };

  render() {
    const { design } = this.props;
    const { password, confirmPassword, error, success, token } = this.state;
    // check if exhibitor is already connected
    const currentStorage = localStorage.getItem(store.getLocalStorageKey());
    if (success || !token || (currentStorage && currentStorage !== '{}')) {
      // redirect to login screen
      return <Redirect to="/login" />;
    }
    const { primaryColor: color } = design;
    return (
      <LoginLayout>
        <Header as="h2" style={{ color }} textAlign="center">
          {getString(`${translationPrefix}.please-enter-new-password`)}
        </Header>
        <Message
          style={{ textAlign: 'left' }}
          icon="info"
          content={getString(`${translationPrefix}.error-password-characters-length`)}
        />
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={getString(`${translationPrefix}.password`)}
          type="password"
          name="password"
          value={password || ''}
          onInput={this.handleChange}
        />
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={getString(`${translationPrefix}.confirm-password`)}
          type="password"
          name="confirmPassword"
          value={confirmPassword || ''}
          onInput={this.handleChange}
          error={confirmPassword && !checkPasswordsSimilarity(password, confirmPassword)}
        />
        <Button
          style={{ backgroundColor: color, color: 'white' }}
          fluid
          size="large"
          onClick={this.handleResetPassword}
          disabled={!password || (password && !checkPasswordsSimilarity(password, confirmPassword))}
        >
          {getString(`${translationPrefix}.reset-password`)}
        </Button>
        {error && (
          <Message
            error
            style={{ textAlign: 'left' }}
            header={getString(`${translationPrefix}.error`)}
            content={error}
          />
        )}
      </LoginLayout>
    );
  }
}

ResetPasswordScreen.propTypes = {
  config: PropTypes.object.isRequired,
  design: PropTypes.object.isRequired,
};

const AutoLoginScreen = (props) => {
  const { config } = props;
  const token = getParameterByName('token');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(token ? undefined : 'auto-login.error-missing-token');
  const { t } = useTranslations(translationPrefix);
  useMount(async () => {
    if (!token) return;

    const { stayConnected = true } = config?.screens?.login || {};
    const res = await store.autoLogin(token, stayConnected);
    if (res && res.success) {
      setSuccess(true);
    } else {
      setError('auto-login.error-invalid-token');
    }
  });

  const { design } = props;
  const { primaryColor: color } = design;

  if (success || !token) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout>
      <Header as="h2" style={{ color }} textAlign="center">
        {t('auto-login.title')}
      </Header>
      {error && (
        <Message
          negative
          icon="warning circle"
          style={{ textAlign: 'left' }}
          header={t('error')}
          content={t(error)}
        />
      )}
      {!error && (
        <Message
          style={{ textAlign: 'left' }}
          icon={{ name: 'refresh', loading: true }}
          content={t('auto-login.description')}
        />
      )}
    </LoginLayout>
  );
};

const ResetOrActiveScreen = (props) => {
  const { config } = props;
  const { mode } = config?.screens?.login || {};
  if (mode === 'otp') {
    return <AutoLoginScreen {...props} />;
  }
  return <ResetPasswordScreen {...props} />;
};

ResetOrActiveScreen.propTypes = {
  config: PropTypes.object.isRequired,
  design: PropTypes.object.isRequired,
};

export default withConfig(withDesign(ResetOrActiveScreen));
