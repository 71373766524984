import React from 'react';
import { Link } from 'react-router-dom';
import { useTracking } from './Context';
import { ExternalLink } from './Layouts/Accordion/components/AccordionFooter';

const OptLink = ({ to, trackClick, children, ...props }) => {
  const { trackEvent } = useTracking();

  function trackGoToPage() {
    trackEvent(trackClick, {});
  }

  if (!to) return <>{children}</>;
  const isExternal = to.indexOf('https://') !== -1;
  const Component = isExternal ? ExternalLink : Link;
  return (
    <Component to={to} onClick={trackClick ? trackGoToPage : undefined} {...props}>
      {children}
    </Component>
  );
};

export default OptLink;
