/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import { Accordion, Header, Icon, Message } from 'semantic-ui-react';
import './Slots.scss';
import { getString } from '../../../utils';
import { SlotGrid } from './SlotGrid';
import { countAvailableSlots, hasAvailableSlots } from './slotUtils';

const SlotDayAccordion = ({ daySlots, onSelect, hideEmpty }) => {
  const [activeIndex, setActiveIndex] = useState(() =>
    findIndex(daySlots, (grp) => hasAvailableSlots(grp.slots)),
  );

  return (
    <Accordion fluid>
      {daySlots.map((grp, index) => {
        const open = index === activeIndex;
        const count = countAvailableSlots(grp.slots);
        if (hideEmpty && !count) return null;
        return [
          <Accordion.Title
            active={open}
            className="slots--title"
            index={0}
            onClick={() => (open ? setActiveIndex(null) : setActiveIndex(index))}
          >
            <Icon name="dropdown" style={{ float: 'right', color: '#000' }} />
            {grp.label}
            <span className="remaining-appointment">
              {getString('appointments.slots.available-slots-count', { count })}
            </span>
          </Accordion.Title>,
          <Accordion.Content active={open}>
            <div className="slots--content">
              <SlotGrid {...grp} onSelect={onSelect} />
            </div>
          </Accordion.Content>,
        ];
      })}
    </Accordion>
  );
};

const SlotDayWithHeader = ({ daySlots, onSelect, hideEmpty }) => {
  return (
    <div>
      {daySlots.map((grp, index) => {
        const count = countAvailableSlots(grp.slots);
        if (hideEmpty && !count) return null;
        return (
          <React.Fragment key={index}>
            <Header as="h3" className="slots--title">
              {grp.label}
              <span className={cx('remaining-appointment', { full: count === 0 })}>
                {getString('appointments.slots.available-slots-count', { count })}
              </span>
            </Header>
            <div className={cx('slots--content', { full: count === 0 })}>
              <SlotGrid {...grp} onSelect={onSelect} displayFullMessage={count === 0} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const slotDayVariants = {
  accordion: SlotDayAccordion,
  header: SlotDayWithHeader,
};

export const SlotDay = ({ header, variant, ...props }) => {
  const Component = slotDayVariants[variant] || SlotDayAccordion;
  return (
    <div className="SlotDay">
      <Header>{header}</Header>
      <Component {...props} />
    </div>
  );
};

SlotDay.defaultProps = {
  hideEmpty: false,
  variant: 'accordion',
};

SlotDay.propTypes = {
  hideEmpty: PropTypes.bool,
  variant: PropTypes.oneOf(['header', 'accordion']),
};
