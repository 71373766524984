import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { userAgenda } from '../../agenda/store/agenda.selectors';
import WorkshopLiveBlock from '../../workshops/blocks/WorkshopLiveBlock';

import { throttledFetchWorkshops } from '../../workshops/store/workshops.actions';
import { useStoreCollectionSync } from '../../utils/useVersionSync';
import { useAutoRefresh } from '../../utils/hooks';
import { upcomingLiveSession } from '../../workshops/utils/session.utils';

const CloudTvBlock = (props) => {
  const { title, videoProps } = props;
  const { allowFullScreen } = videoProps;

  const agenda = useSelector(userAgenda);
  const now = useAutoRefresh(60000);

  const currentLiveStreams = upcomingLiveSession(agenda, now);

  useStoreCollectionSync({ resourceType: 'workshops', fetcher: throttledFetchWorkshops });

  if (!currentLiveStreams) return null;

  return (
    <div className="block--cloudtv">
      <WorkshopLiveBlock
        defaultActive
        allowFullScreen={allowFullScreen}
        item={currentLiveStreams}
        title={title}
        videoProps={videoProps}
      />
    </div>
  );
};

CloudTvBlock.defaultProps = {
  title: undefined,
  videoProps: {},
};

CloudTvBlock.propTypes = {
  title: PropTypes.string,
  videoProps: PropTypes.shape({
    allowFullScreen: PropTypes.bool,
  }),
};

export default connect((state) => ({
  workshops: state.workshops.workshops,
}))(CloudTvBlock);
