import React from 'react';
import trim from 'lodash/trim';

const newlineRegex = /(\r\n|\r|\n)/g;

const nl2br = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  return trim(str, '\n\r')
    .split(newlineRegex)
    .map((line, index) => {
      if (line.match(newlineRegex)) {
        // eslint-disable-next-line react/no-array-index-key
        return <br key={index} />;
      }
      return line;
    });
};

export default nl2br;
