import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useMedia } from 'react-media';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Icon, Item, Label, Segment } from 'semantic-ui-react';
import Blocks from '../components/Blocks';
import DescriptionBlock from '../components/sponsors/DescriptionBlock';
import VideosBlock from '../components/video/VideosBlock';
import WorkshopsMatchingBlock from '../components/workshops/WorkshopsMatchingBlock';
import { TrackingContext } from '../Context';
import { useIsPreview } from '../config/preview.context';
import { useScreenConfig } from '../config/screens.context';
import { eventTags } from '../core/trackers/events';
import { userProptypes } from '../propTypes';
import store from '../shared/Store';
import { getString } from '../utils';
import Images from '../utils/Images';
import { GLOBAL_MEDIA_QUERIES } from '../utils/mediaQueries';
import { useStoreVersionSync } from '../utils/useVersionSync';
import { sidebarBlockComponents } from './blocks';
import SponsorChat from './blocks/SponsorChat';
import VideoEmbed from '../components/VideoEmbed';
import CdnImage from '../components/CdnImage';
import { fetchSponsor } from './store/sponsors.actions';
import SidebarBlocks from '../components/SidebarBlocks';

const translationPrefix = 'sponsors.sponsor';

// TODO: move to CSS
const colors = {
  platinum: 'blue',
  gold: 'yellow',
  silver: 'grey',
};

export const SponsorBannerLogo = ({ banner, logo }) => (
  <div style={{ margin: '-1em -1em 54px -1em', position: 'relative' }}>
    <div
      style={{
        backgroundImage: `url(${Images.cdn(banner)})`,
        width: '100%',
        paddingTop: '30%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    />
    <div
      style={{
        position: 'absolute',
        left: 30,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        background: 'white',
        padding: 4,
        borderRadius: 4,
        bottom: -40,
      }}
    >
      <CdnImage src={logo} maxHeight={90} style={{ height: 90 }} />
    </div>
  </div>
);

export const Sponsor = ({ sponsor }) => {
  const {
    _id,
    url,
    logo,
    name,
    collection,
    category,
    description,
    shortDescription,
    videos,
    video,
  } = sponsor;
  const { sponsor: { rightSidebar, banner, enableChat } = {} } = useScreenConfig('sponsors');
  const isPreview = useIsPreview();
  const { trackEvent } = useContext(TrackingContext);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const marginBottom = matches.mobile ? 70 : 0;

  function trackGoToPage() {
    trackEvent(eventTags.SPONSOR_GO_TO_PAGE, {
      userId: store.user._id,
      item: sponsor,
    });
  }

  const rightWidth = rightSidebar ? rightSidebar.width || 4 : 0;

  return (
    <div className={`screen--type-sponsor ${collection}--${_id}`} style={{ marginBottom }}>
      {!isPreview && (
        <Button
          style={{ backgroundColor: 'transparent', paddingLeft: 0, color: 'white' }}
          as={Link}
          to="/sponsors"
        >
          <Icon name="angle left" /> {getString(`${translationPrefix}.back-to-sponsor`)}
        </Button>
      )}
      <Grid>
        <Grid.Column width={16 - rightWidth}>
          <Segment>
            <SponsorBannerLogo banner={banner} logo={logo} />
            <Header as="h2" style={{ marginBottom: 0 }}>
              {name}
            </Header>

            <Item>
              <Item.Content>
                {url && (
                  <Item.Description>
                    <a href={url} target="_blank" rel="noreferrer noopener" onClick={trackGoToPage}>
                      {url}
                    </a>
                  </Item.Description>
                )}
              </Item.Content>
            </Item>
            {category && (
              <Label
                icon="trophy"
                color={colors[category]}
                content={category}
                style={{ margin: '10px 0px' }}
              />
            )}
            <Item>
              <Item.Content>
                <Item.Description>{shortDescription}</Item.Description>
                <br />
                {video && <VideoEmbed icon="play" url={video.uri} allowFullScreen />}
              </Item.Content>
            </Item>
          </Segment>
          {description && <DescriptionBlock description={description} />}
          <VideosBlock videos={videos} />
          <WorkshopsMatchingBlock field="sponsors" value={_id} />
        </Grid.Column>
        <SidebarBlocks
          sidebar={rightSidebar}
          blockComponents={sidebarBlockComponents}
          sharedProps={{
            item: sponsor,
            translationPrefix,
          }}
        />
      </Grid>
      {!isPreview && enableChat && (
        <SponsorChat
          sponsorId={_id}
          sponsor={sponsor}
          translationPrefix={translationPrefix}
          type="sponsors"
        />
      )}
    </div>
  );
};

Sponsor.defaultProps = {
  sponsor: {},
};

Sponsor.propTypes = {
  sponsor: PropTypes.shape(userProptypes),
};

function EnsureSponsor({ sponsor, ...props }) {
  const sponsorId = sponsor?._id;

  useStoreVersionSync({
    fetcher: fetchSponsor,
    resourceType: 'sponsors',
    id: sponsorId,
    actualVersion: sponsor?.version,
  });

  if (!sponsor) return null; // Loading ?

  return <Sponsor sponsor={sponsor} {...props} />;
}

export default connect((state, props) => {
  const { id } = props.match.params;
  return {
    sponsor: state.sponsors.sponsors.find((s) => (s.slug ? s.slug === id : s._id === id)),
  };
})(EnsureSponsor);
