import React from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { useScreenConfig } from '../../config/screens.context';
import { getString } from '../../utils';
import { localeFormat } from '../../utils/date';
import { SlotDay } from '../components/Slots/SlotDay';
import { StepContainer } from '../components/Step';
import { useStepContext } from './StepContext';

const translationPrefix = 'appointments.steps';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

export const StepSelectSlotSidebar = () => {
  const { onSelect, freeTimeSlots } = useStepContext();
  const nextSlot = (freeTimeSlots || [])[0];

  return (
    <div>
      <StepContainer label="1" active>
        <Header as="h4">{t('select-slot.sidebar-label')}</Header>
        {nextSlot && (
          <>
            <p>{t('select-slot.next-free')}</p>
            <Button primary size="tiny" compact onClick={() => onSelect(nextSlot)}>
              {localeFormat(nextSlot.startDate, 'PPPp')}
            </Button>
          </>
        )}
        {!nextSlot && <p>{t('select-slot.no-slot-left')}</p>}
      </StepContainer>
      <StepContainer label="2" top>
        {t('confirm.sidebar-label')}
      </StepContainer>
      <StepContainer label="3" top>
        {t('prepare.sidebar-label')}
      </StepContainer>
    </div>
  );
};
export const StepSelectSlotContent = () => {
  const { components = {} } = useScreenConfig('appointments');
  const { days, onSelect } = useStepContext();

  return (
    <Grid columns={days.length} centered style={{ marginTop: 0, flexWrap: 'wrap' }} stackable>
      {days.map((day, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid.Column key={index} style={{ minWidth: 320, flexGrow: 1 }}>
          <SlotDay {...day} onSelect={onSelect} {...components.slotDay} />
        </Grid.Column>
      ))}
    </Grid>
  );
};
