import store from '../../shared/Store';
import platformService from '../services/platform.service';
import { trackEvent as tcTrackEvent, trackPage as tcTrackPage } from './tagCommanderTracker';
import { generateEvent, getCurrentPage } from './utils';

export function trackEvent(eventName, payload) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`trackEvent ${eventName} - blocked in dev`);
    return;
  }
  const isUserConnected = store?.user?._id;
  if (!isUserConnected) return;

  // Future switch on tracker types
  const stats = window.__DATA__?.screens?.stats || {};

  if (stats[eventName]) {
    const event = generateEvent(eventName, payload, stats);
    if (event) {
      // Push to server...
      platformService.stats(event.tag, event.payload);
    }
  }

  // Future switch on tracker types
  if (window.__DATA__?.tagCommander?.trackEvents) {
    const event = generateEvent(eventName, payload, window.__DATA__.tagCommander.trackEvents);
    if (event) {
      tcTrackEvent(event);
    }
  }
}

export function trackPage(locationPath, menu) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`trackPage ${locationPath} - blocked in dev`);
    return;
  }
  const stats = window.__DATA__?.screens?.stats || {};
  const { trackPages = true } = stats;

  const isUserConnected = store?.user?._id;
  if (!isUserConnected) return;
  // Default appcraft events tracking, no await needed
  if (trackPages) {
    platformService.stats('VIEW_PAGE', {
      path: locationPath,
      url: window.location.href,
    });
  }

  const page = getCurrentPage(menu, locationPath);
  if (!page) return; // Not sure what to track...

  if (window.__DATA__?.tagCommander?.trackPages) {
    tcTrackPage(locationPath, page);
  }
}
