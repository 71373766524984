/* eslint-disable operator-linebreak */
import { differenceInHours, format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { useResourceLink } from '../../utils/hooks';
import { workshopPropType } from '../utils';
import './WorkshopAddToCalendarBlock.scss';

const translationPrefix = 'workshops.workshop';
const dateFormatForCalendar = `yyyyMMdd'T'HHmmss`;
export const availableCalendar = [SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK, SHARE_SITES.ICAL];

export const AddWorkshopToCalendar = ({ workshop, ...props }) => {
  const url = useResourceLink(workshop, true);
  const startAt = parseISO(workshop.startDate);
  const endAt = parseISO(workshop.endDate);
  const { trackEvent } = useContext(TrackingContext);
  const event = {
    description: `${workshop.shortDescription || ''}. \n${url}`,
    duration: differenceInHours(endAt, startAt),
    endDatetime: format(endAt, dateFormatForCalendar),
    startDatetime: format(startAt, dateFormatForCalendar),
    // timezone: 'Europe/Paris', // Don't force the timezone unless you force it when formatting time
    title: workshop.title,
  };

  function onAdd() {
    trackEvent(eventTags.WORKSHOP_ADD_TO_CALENDAR, { userId: store.user._id, workshop });
  }

  return (
    <AddToButton
      event={event}
      items={availableCalendar}
      onAdd={onAdd}
      buttonProps={{ onMouseDown: onAdd, ...props }}
    />
  );
};

AddWorkshopToCalendar.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const WorkshopAddToCalendarBlock = memo(({ workshop }) => {
  return (
    <div className="WorkshopAddToCalendarBlock--Container">
      <AddWorkshopToCalendar workshop={workshop} />
    </div>
  );
});

WorkshopAddToCalendarBlock.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const AddAppointmentToCalendar = ({ appointment, sponsor, ...props }) => {
  const startAt = parseISO(appointment.startDate);
  const endAt = parseISO(appointment.endDate);
  const url = useResourceLink(sponsor, true);

  const { trackEvent } = useContext(TrackingContext);
  const event = {
    title: getString(`appointments.slots.meeting-with`, {
      name: sponsor.name,
    }),
    description: `${sponsor.shortDescription || ''}. \n${url}`,
    duration: differenceInHours(endAt, startAt),
    endDatetime: format(endAt, dateFormatForCalendar),
    startDatetime: format(startAt, dateFormatForCalendar),
    // timezone: 'Europe/Paris', // Don't force the timezone unless you force it when formatting time
  };

  function onAdd() {
    trackEvent(
      sponsor.collection === 'program'
        ? eventTags.PROGRAM_APPOINTMENT_ADD_TO_CALENDAR
        : eventTags.SPONSOR_APPOINTMENT_ADD_TO_CALENDAR,
      {
        userId: store.user._id,
        appointment,
        item: sponsor,
      },
    );
  }

  return (
    <AddToButton
      event={event}
      items={availableCalendar}
      onAdd={onAdd}
      buttonProps={{ onMouseDown: onAdd, ...props }}
    />
  );
};

AddAppointmentToCalendar.propTypes = {
  appointment: PropTypes.object.isRequired,
  sponsor: PropTypes.object.isRequired,
};

export const AppointmentAddToCalendarBlock = memo(({ appointment, sponsor, ...props }) => {
  return (
    <div className="WorkshopAddToCalendarBlock--Container">
      <AddAppointmentToCalendar appointment={appointment} sponsor={sponsor} {...props} />
    </div>
  );
});

AppointmentAddToCalendarBlock.propTypes = {
  appointment: PropTypes.object.isRequired,
  sponsor: PropTypes.object.isRequired,
};

function createFakeEvent() {
  const eventType = 'fake-event';
  if (typeof window.CustomEvent === 'function') {
    return new CustomEvent(eventType);
  }
  // Old browser fallback
  const event = document.createEvent(eventType);
  event.initCustomEvent(eventType, false, false, null);
  return event;
}

const AddToModal = ({ children, isOpen, onRequestClose }) => {
  return (
    <Dropdown
      ref={(ref) => {
        if (ref && isOpen) {
          ref.open();
        }
      }}
      className="WorkshopAddToCalendarBlock--Dropdown"
      onClose={() => onRequestClose(createFakeEvent())}
      trigger={<></>}
    >
      <Dropdown.Menu>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            className: 'item',
          }),
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
AddToModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

const CalendarButton = (props) => (
  <Button {...props} style={{ width: '100%' }}>
    <Icon name="calendar alternate outline" size="small" />
    {getString(`${translationPrefix}.add-to-calendar`)}
  </Button>
);

CalendarButton.defaultProps = {
  size: 'tiny',
};

CalendarButton.propTypes = {
  size: PropTypes.string,
};

const AddToButton = AddToCalendarHOC(CalendarButton, AddToModal);
