/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header, Segment } from 'semantic-ui-react';
import VideoCard from '../video/VideoCard';

const VideosBlock = (props) => {
  const { title, subtitle, files } = props;

  if (!files?.length) return null;
  return (
    <Segment className="block--cms-files">
      <Header as="h3" style={{ marginBottom: 0 }}>
        {title}
      </Header>
      <Header as="h4" style={{ marginTop: 0 }}>
        {subtitle}
      </Header>
      <Card.Group>
        {files.map((video, index) => (
          <VideoCard key={video._id || index} video={video} />
        ))}
      </Card.Group>
    </Segment>
  );
};

VideosBlock.defaultProps = {
  files: undefined,
  subtitle: undefined,
};
VideosBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  files: PropTypes.array,
};

export default VideosBlock;
