import React from 'react';
import loadable from '@loadable/component';
import { useDesignConfig } from '../config/design.context';
import layoutProps from './layoutProps';

const layouts = {
  default: loadable(() => import('./Default/DefaultLayout')),
  accordion: loadable(() => import('./Accordion/AccordionLayout')),
};

const Layout = (props) => {
  const { layout } = useDesignConfig();
  const Renderer = layouts[layout] || layouts.default;

  return <Renderer {...props} />;
};

Layout.propTypes = layoutProps;

export default Layout;
