import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import BlockHeader from './BlockHeader';

const BlockContainer = ({ className, childWrapperClassName, type, header, children }) => {
  if (type === 'segment') {
    return (
      <Segment.Group className={className}>
        {header && (
          <Segment>
            <Header as="h3">{header}</Header>
          </Segment>
        )}
        <Segment className={childWrapperClassName}>{children}</Segment>
      </Segment.Group>
    );
  }
  if (type === 'segment-light') {
    return (
      <Segment className={className}>
        {header && <Header as="h3">{header}</Header>}
        {children}
      </Segment>
    );
  }
  if (type === 'basic')
    return (
      <div className={className}>
        <BlockHeader title={header} />
        {children}
      </div>
    );
  return <div className={className}>{children}</div>;
};

BlockContainer.defaultProps = {
  childWrapperClassName: undefined,
  className: undefined,
  header: undefined,
  type: 'segment',
};

BlockContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  childWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  type: PropTypes.oneOf(['segment', 'segment-light', 'basic', 'headerless']),
};

export default BlockContainer;
