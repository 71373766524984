// eslint-disable-next-line import/no-cycle
import platformService from '../../core/services/platform.service';
import { setUsers } from '../../core/users/users.actions';
// eslint-disable-next-line import/no-cycle
import { setPrograms } from '../../programs/store/programs.actions';
// eslint-disable-next-line import/no-cycle
import { setSponsors } from '../../sponsors/store/sponsors.actions';
// eslint-disable-next-line import/no-cycle
import { setWorkshops } from '../../workshops/store/workshops.actions';
import { setAppointments } from '../reducers/appointments';
import { setRegisrations } from '../reducers/registrations';
import { setTimeSlots } from '../reducers/timeSlots';
import { setStoreUser } from '../reducers/user';

/**
 * WORKSHOP
 */

export function fetchUser() {
  return async (dispatch) => {
    const user = await platformService.fetchUser();
    dispatch(setStoreUser(user));
  };
}

export function fetchTimeSlots(collection, groupId) {
  return async (dispatch) => {
    const timeSlots = await platformService.fetchTimeSlots(collection, groupId);
    dispatch(setTimeSlots(timeSlots, groupId));
  };
}

export function fetchRegistrations() {
  return async (dispatch) => {
    const { registrations } = await platformService.fetchRegistrations();
    if (registrations) {
      dispatch(setRegisrations(registrations));
    }
  };
}

export function fetchAppointments() {
  return async (dispatch) => {
    const appointments = await platformService.fetchAppointments();
    dispatch(setAppointments(appointments));
  };
}

export function dispatchData(data) {
  const { workshops, sponsors, programs, speakers } = data;
  return (dispatch) => {
    if (workshops) dispatch(setWorkshops(workshops));
    if (sponsors) dispatch(setSponsors(sponsors));
    if (programs) dispatch(setPrograms(programs));
    if (speakers) dispatch(setUsers('speakers', speakers));
  };
}
