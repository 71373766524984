import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import ItemCardGroup from '../../components/ItemCardGroup';
import { ClickableImage } from '../../headers/blocks/Banner';

import './Logos.scss';
import { logoPropType, logoArrayPropTypes } from '../../propTypes';
import { cdnImagePropTypes } from '../../components/CdnImage';

const LogoCard = (props) => {
  const { item, trackClick, maxWidth, maxHeight, func } = props;
  const { image, link } = item;
  return (
    <Card>
      <ClickableImage
        image={image}
        link={link}
        trackClick={trackClick}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
      />
    </Card>
  );
};
LogoCard.defaultProps = {
  trackClick: undefined,
  maxWidth: undefined,
  maxHeight: 500,
  func: 'fit',
};
LogoCard.propTypes = {
  item: logoPropType.isRequired,
  trackClick: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  func: cdnImagePropTypes.func,
};

const Logos = (props) => {
  const { containerType, header, logos, itemsPerRow, groupBy } = props;
  if (!logos) return null;

  return (
    <BlockContainer className="block--logos" header={header} type={containerType}>
      <ItemCardGroup
        centered
        groupClassName="logos"
        items={logos}
        itemsPerRow={itemsPerRow}
        groupBy={groupBy}
        component={LogoCard}
      />
    </BlockContainer>
  );
};

Logos.defaultProps = {
  containerType: 'headerless',
  groupBy: undefined,
  header: undefined,
  itemsPerRow: undefined,
};

Logos.propTypes = {
  containerType: PropTypes.string,
  groupBy: PropTypes.string,
  header: PropTypes.string,
  itemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logos: logoArrayPropTypes.isRequired,
};

export default Logos;
