export function randomItem(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function updateOrAppendItem(list, item) {
  if (list.find((i) => i._id === item._id)) {
    // Update in list
    return list.map((i) => (i._id === item._id ? item : i));
  }
  return [...list, item]; // Append
}
