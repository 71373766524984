import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Blocks from './Blocks';

const SidebarBlocks = ({ sidebar, sharedProps, blockComponents }) => {
  if (!sidebar) return null;
  const { width = 4, blocks } = sidebar;
  if (!blocks || blocks.length === 0) return null;
  return (
    <Grid.Column width={width || 4}>
      <Blocks blocks={blocks} blockComponents={blockComponents} sharedProps={sharedProps} />
    </Grid.Column>
  );
};

SidebarBlocks.defaultProps = {
  sidebar: undefined,
  sharedProps: undefined,
};

SidebarBlocks.propTypes = {
  blockComponents: PropTypes.object.isRequired,
  sharedProps: PropTypes.object,
  sidebar: PropTypes.shape({
    width: PropTypes.number,
    blocks: PropTypes.array.isRequired,
  }),
};

export default SidebarBlocks;
