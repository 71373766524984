/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Header, Item, Segment } from 'semantic-ui-react';
import { getString } from '../../utils';

const translationPrefix = 'blocks';

const DescriptionBlock = ({ description }) => {
  return (
    <Segment>
      <Header as="h3">{getString(`${translationPrefix}.description`)}</Header>
      <Item>
        <Item.Content>
          <Item.Description>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Item.Description>
        </Item.Content>
      </Item>
    </Segment>
  );
};

DescriptionBlock.propTypes = {
  description: PropTypes.string.isRequired,
};

export default DescriptionBlock;
