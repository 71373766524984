/* eslint-disable operator-linebreak */
import { useCallback } from 'react';
import { getString } from '../utils';

export default function useTranslations(prefix) {
  const t = useCallback(prefix ? (key, data) => getString(`${prefix}.${key}`, data) : getString, [
    prefix,
  ]);
  return { t };
}
