import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import trimStart from 'lodash/trimStart';
import trimEnd from 'lodash/trimEnd';

function ensureString(v) {
  if (typeof v === 'number') return v.toString();
  return v || '';
}

// eslint-disable-next-line import/prefer-default-export
export function replaceValues(text, item) {
  if (!text) return '';
  return text.replace(/{(.*?)}/g, (m, key) => ensureString(get(item, key)));
}

export function replaceUriValues(text, item) {
  if (!text) return '';
  return text.replace(/{(.*?)}/g, (m, key) => encodeURIComponent(ensureString(get(item, key))));
}

export function urlify(text) {
  if (!text) return '';
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

export function markdownUrlify(text, options = {}) {
  if (!text) return '';
  const { onLinkClick } = options;
  const urlRegex = /(\[[^\]]*\]\([^)]*\))/g;
  const splitRegex = /\[([^\]]*)\]\(([^)]*)\)/;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      // Split
      const innerMatch = part.match(splitRegex);
      if (innerMatch) {
        const [, urlText, url] = innerMatch;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer" onClick={onLinkClick}>
            {urlText}
          </a>
        );
      }
    }
    return urlify(part);
  });
}

/**
 * Simiar to path.join. Ensures middle slashes and prevents double slashes
 * @param {*} paths array of paths
 */
// eslint-disable-next-line import/prefer-default-export
export function urlJoin(paths) {
  if (!paths || paths.length === 0) return '';
  const validPaths = paths.filter((p) => p);
  return validPaths
    .map((p, index) => {
      if (index === 0) return trimEnd(p, '/ ');
      if (index === validPaths.length - 1) return trimStart(p, '/ ');
      return trim(p, '/ ');
    })
    .join('/');
}
