import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import './avatars.scss';
import Images from '../utils/Images';
import SquareImage from './SquareImage';
import { avatarWithFallbackWhenMissing } from '../utils/avatarUtils';

const Avatars = ({ users, size, imageSize, imageKey, maxCount }) => {
  return (
    <div className="avatars">
      {users
        .filter((user) => Images.exists(user[imageKey]))
        .slice(0, maxCount)
        .map((user) => (
          <Popup
            key={user._id}
            content={`${user.firstName || ''} ${user.lastName || ''}`}
            trigger={
              <SquareImage
                size={size}
                src={avatarWithFallbackWhenMissing(user[imageKey])}
                imageSize={imageSize}
                circular
                style={{ cursor: 'pointer' }}
              />
            }
          />
        ))}
    </div>
  );
};

Avatars.defaultProps = {
  imageKey: 'thumbnail',
  maxCount: 5,
  imageSize: 28,
  size: undefined,
};

Avatars.propTypes = {
  imageKey: PropTypes.string,
  imageSize: PropTypes.number,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
  maxCount: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Avatars;
