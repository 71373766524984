import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Icon, List } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import { TimeDisplay } from '../../components/DateTimeDisplay';
import ExpiringNavLink from '../../components/ExpiringNavLink';
import { useScreensConfig } from '../../config/screens.context';
import { containerTypePropTypes } from '../../propTypes';
import { getString } from '../../utils';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { replaceValues } from '../../utils/stringUtils';
import { getClassName } from '../../workshops/utils';
import { userAgenda } from '../store/agenda.selectors';
import './UserAgendaBlock.scss';

const translationPrefix = 'home';

export function workshopPath(workshop) {
  return `/schedule/${workshop.category}/${workshop.slug || workshop._id}`;
}

function sponsorProgramPath(item, globalConfig) {
  const { sponsors = {}, programs = {} } = globalConfig;
  const itemConfig = (item.collection === 'program' ? programs?.program : sponsors?.sponsor) || {};
  const { canonicalPath } = itemConfig;

  if (canonicalPath) {
    return replaceValues(canonicalPath, item);
  }
  return `/${item.collection}/${item.slug || item._id}`;
}

function eventPath(event, config) {
  switch (event.type) {
    case 'workshop':
      return workshopPath(event);
    case 'appointment': {
      return sponsorProgramPath(event.sponsor, config);
    }
    default:
      return null;
  }
}
const SessionItem = ({ event, clickableSession }) => {
  const config = useScreensConfig();
  const { title, startDate, endDate } = event;
  const as = clickableSession ? ExpiringNavLink : 'div';
  return (
    <List.Item
      className={`item--workshop ${getClassName(event)}`}
      as={as}
      to={eventPath(event, config)}
    >
      <List.Icon name="circle" size="tiny" verticalAlign="top" />
      <List.Content>
        <Header>{title}</Header>
        <List.Description>
          <TimeDisplay startDate={startDate} endDate={endDate} />
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

SessionItem.defaultProps = {
  clickableSession: true,
};
SessionItem.propTypes = {
  event: PropTypes.shape({
    category: PropTypes.string,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  clickableSession: PropTypes.bool,
};

const SessionDay = ({ day, events, clickableSession }) => {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h4">{t('common.date', { date: day, context: 'user_agenda' })}</Header>
      <List relaxed animated={clickableSession}>
        {events.map((event) => (
          <SessionItem key={event._id} event={event} clickableSession={clickableSession} />
        ))}
      </List>
    </>
  );
};

SessionDay.defaultProps = {
  clickableSession: true,
};

SessionDay.propTypes = {
  day: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  clickableSession: PropTypes.bool,
};

export const GroupedUserSessions = ({ sessions, ...rest }) => {
  const { days, eventsByDay } = groupEventsByDays(sessions);
  return (
    <>
      {days.map((day) => (
        <SessionDay key={day} day={day} events={eventsByDay[day]} {...rest} />
      ))}
    </>
  );
};

GroupedUserSessions.propTypes = {
  sessions: PropTypes.array.isRequired,
};

const UserAgendaBlock = ({ header, containerType, clickableSession }) => {
  const sessions = useSelector(userAgenda);
  return (
    <BlockContainer className="block--user-agenda" type={containerType} header={header}>
      <div>
        <GroupedUserSessions sessions={sessions} clickableSession={clickableSession} />
        <Link to="/profile" className="view-all">
          {getString(`${translationPrefix}.view-all`)} <Icon name="angle right" />
        </Link>
      </div>
    </BlockContainer>
  );
};

UserAgendaBlock.defaultProps = {
  containerType: 'segment',
  header: undefined,
  clickableSession: true,
};

UserAgendaBlock.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  clickableSession: PropTypes.bool,
};

export default UserAgendaBlock;
