import React from 'react';
import { NavLink } from 'react-router-dom';
import store from '../shared/Store';
import { useAutoRefresh } from '../utils/hooks';

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const LINK_EXPIRATION_DELAY = 120 * MINUTES;
const startTime = Date.now();

function timeLeft() {
  return startTime + LINK_EXPIRATION_DELAY - Date.now();
}

function hasExpired() {
  return store.hasSavedUser() && timeLeft() <= 0;
}

const ExpiringNavLink = (props) => {
  useAutoRefresh(Math.max(1 * MINUTES, timeLeft()));
  return <NavLink {...props} component={hasExpired() ? 'a' : undefined} />;
};

ExpiringNavLink.propTypes = NavLink.propTypes;

export default ExpiringNavLink;
