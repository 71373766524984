import differenceInMinutes from 'date-fns/differenceInMinutes';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Ref } from 'semantic-ui-react';
import nl2br from '../../utils/nl2br';
import './Chat.scss';
import ChatFeed from './ChatFeed';
import ChatInput from './ChatInput';
import useChatScroll from './useChatScroll';

const minutesBetweenMessageGroup = 2;
function useFormatMessages(originalMessages) {
  return useMemo(() => {
    const mostRecentLast = (a, b) => a.createdAt - b.createdAt;
    return Object.values(originalMessages || {})
      .map((message) => ({
        ...message,
        text: nl2br(message.text),
        createdAt: new Date(message.createdAt),
      }))
      .sort(mostRecentLast)
      .reduce((acc, message) => {
        const lastMessage = acc[acc.length - 1];
        if (
          lastMessage?.emitter === message.emitter &&
          differenceInMinutes(message.createdAt, lastMessage.createdAt) < minutesBetweenMessageGroup
        ) {
          lastMessage.text = lastMessage.text.concat({
            id: message.id,
            text: message.text,
          });
          // Date should be last date...
          lastMessage.createdAt = message.createdAt;
          return acc;
        }

        return acc.concat({
          ...message,
          text: [
            {
              id: message.id,
              text: message.text,
            },
          ],
        });
      }, []);
  }, [originalMessages]);
}

const Chat = ({ headerMessage, messages, me, sponsorImage, onSendMessage, onDelete }) => {
  const formatMessages = useFormatMessages(messages);
  const scrollElementRef = useChatScroll(formatMessages);
  return (
    <div className="Chat">
      <Ref innerRef={scrollElementRef}>
        <ChatFeed
          feedHeader={headerMessage}
          messages={formatMessages}
          me={me}
          sponsorImage={sponsorImage}
          onDelete={onDelete}
        />
      </Ref>
      <ChatInput onSubmit={onSendMessage} />
    </div>
  );
};

Chat.defaultProps = {
  headerMessage: undefined,
  sponsorImage: undefined,
  onDelete: undefined,
};

Chat.propTypes = {
  headerMessage: PropTypes.shape({
    text: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      emitter: PropTypes.string.isRequired,
      profile: PropTypes.shape({
        isSponsor: PropTypes.bool,
        name: PropTypes.string.isRequired,
        thumbnail: PropTypes.object,
      }),
    }),
  ).isRequired,
  me: PropTypes.string.isRequired,
  sponsorImage: PropTypes.shape({ uri: PropTypes.string }),
  onDelete: PropTypes.func,
  onSendMessage: PropTypes.func.isRequired,
};

export default Chat;
