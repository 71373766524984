/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import BlockContainer from '../BlockContainer';
import { containerTypePropTypes } from '../../propTypes';

function HtmlBlock(props) {
  const { title, content, containerType } = props;
  if (!content) return null;
  return (
    <BlockContainer type={containerType} header={title} className="block--html">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </BlockContainer>
  );
}

HtmlBlock.defaultProps = {
  content: undefined,
  containerType: 'segment-light',
  title: undefined,
};

HtmlBlock.propTypes = {
  content: PropTypes.string,
  containerType: containerTypePropTypes,
  title: PropTypes.string,
};

export default HtmlBlock;
