import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { imageProptypes } from '../../propTypes';
import './cta-tile-block.scss';
import Images from '../../utils/Images';

function CTATileBlock(props) {
  const { title, subtitle, icon, to, backgroundImage, style } = props;
  return (
    <Segment
      as={NavLink}
      to={to}
      inverted={!!backgroundImage}
      className={cx(!!backgroundImage && 'segment--background-image')}
      style={{ ...style, backgroundImage: `url("${Images.maxWidth(backgroundImage, 600)}")` }}
    >
      <Header as="h2" icon={icon} content={title} subheader={subtitle} />
    </Segment>
  );
}

CTATileBlock.defaultProps = {
  backgroundImage: undefined,
  icon: undefined,
  style: undefined,
  subtitle: undefined,
};

CTATileBlock.propTypes = {
  backgroundImage: imageProptypes,
  icon: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CTATileBlock;
