import PropTypes from 'prop-types';
import { getEntityString } from '../../utils';
import cx from 'classnames';

// eslint-disable-next-line import/prefer-default-export
export const workshopPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  speakers: PropTypes.array,
  files: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
});

export function getWorkshopString(workshop, key) {
  return getEntityString(workshop, 'workshop', key);
}
function quotaState(userCount, quota) {
  if (!quota) return undefined;
  if (userCount >= quota) return 'full';
  return 'available';
}

export function getClassName(workshop) {
  const { _id, category, quota, usersCount } = workshop;
  return cx(
    `workshop--${category} workshop--${category}--${_id}`,
    quota && `workshop--quota`,
    quota && `workshop--quota--${quotaState(usersCount, quota)}`,
  );
}
