import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Flockler from '../components/Flockler/Flockler';

const FlocklerBlock = ({ config }) => (
  <Grid.Row stretched>
    <Grid.Column width={16}>
      <Flockler projectId={config.projectId} feedId={config.feedId} />
    </Grid.Column>
  </Grid.Row>
);

FlocklerBlock.propTypes = {
  config: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    feedId: PropTypes.string.isRequired,
  }).isRequired,
};

export default FlocklerBlock;
