import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button, Card, Label } from 'semantic-ui-react';
import CdnImage from '../CdnImage';
import ExpiringNavLink from '../ExpiringNavLink';
import './ReplayCard.scss';
import { getString } from '../../utils';

const translationPrefix = 'replay.card';

const ReplayCard = (props) => {
  const { item, onClick } = props;
  const { _id, category, collection, image, slug, title, replays } = item;

  const { value = {} } = replays?.[0] || {};
  const { downloadUri } = value;

  function onDownload(e) {
    e.stopPropagation();
    // TODO: track download for stats :p
  }

  const handleClick = () => {
    if (replays?.[0]) {
      onClick({
        ...replays?.[0],
        title,
      });
    }
  };

  return (
    <Card className={`card--replay ${collection}--${_id}`}>
      {image && (
        <Card.Content style={{ flexGrow: 0, padding: 0 }}>
          <CdnImage as="img" src={image} alt="image" className="image" maxWidth={300} />
        </Card.Content>
      )}
      <Card.Content>
        <Card.Header style={{ fontWeight: 'normal', fontSize: 16 }}>
          <ExpiringNavLink
            style={{ color: 'rgba(0,0,0,.85)' }}
            to={`/schedule/${category}/${slug || _id}`}
          >
            {title}
          </ExpiringNavLink>
        </Card.Header>
      </Card.Content>
      <Card.Content extra className="card--replay button-box" style={{ borderTop: '0' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 0,
          }}
        >
          {category && (
            <Label
              basic
              color="blue"
              size="small"
              className={`replay--${category}`}
              content={category}
            />
          )}
          <Button color="blue" floated="right" onClick={handleClick}>
            {getString(`${translationPrefix}.see-again`)}
          </Button>
        </div>
      </Card.Content>
      <Card.Content className="action-buttons" extra style={{ padding: 0 }}>
        <Button.Group basic fluid size="small">
          <Button
            as={ExpiringNavLink}
            className="know-more__button"
            color="blue"
            content="En savoir plus"
            style={{
              textAlign: downloadUri ? 'left' : undefined,
            }}
            to={`/schedule/${category}/${slug || _id}`}
          />
          {downloadUri && (
            <Button
              as="a"
              className="download__button"
              color="blue"
              icon="download"
              style={{ flexGrow: 0 }}
              href={downloadUri}
              onClick={onDownload}
            />
          )}
        </Button.Group>
      </Card.Content>
    </Card>
  );
};

ReplayCard.defaultProps = {
  onClick: noop,
};

ReplayCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    category: PropTypes.string,
    collection: PropTypes.string,
    image: PropTypes.object,
    replays: PropTypes.array,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

export default ReplayCard;
