import { all, fork } from 'redux-saga/effects';
import networkingSagas from '../networking/store/networking.sagas';
import { firebaseSaga } from './effects/firebase.effects';
import notificationsSagas from './notifications/notifications.sagas';

function* mainSaga() {
  yield all([firebaseSaga, networkingSagas, notificationsSagas].map(fork));
}

export default mainSaga;
