import React from 'react';
import cx from 'classnames';
import { Button, Grid } from 'semantic-ui-react';
import './Slots.scss';
import { formatTime } from './slotUtils';

const LineWrapper = ({ children, left, right }) => (
  <div className="LineWrapper">
    <div className={cx('LineWrapper__line', left && 'show')} />
    {children}
    <div className={cx('LineWrapper__line', right && 'show')} />
  </div>
);

LineWrapper.defaultProps = {
  left: true,
  right: true,
};

const SlotLine = ({ startTime, slots, onSelect }) => {
  return (
    <Grid columns={slots.length + 1} verticalAlign="middle" textAlign="center">
      <Grid.Column>{formatTime(startTime)}</Grid.Column>
      {slots.map((slot, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid.Column key={index}>
          <LineWrapper left={index > 0} right={index < slots.length - 1}>
            <Button compact size="mini" disabled={!slot.isAvailable} onClick={() => onSelect(slot)}>
              {formatTime(slot.startDate)}
            </Button>
          </LineWrapper>
        </Grid.Column>
      ))}
    </Grid>
  );
};

export default SlotLine;
