import PropTypes from 'prop-types';
import React from 'react';
import { useConfig } from '../config/config.context';
import { useScreenConfig } from '../config/screens.context';
import { replaceValues } from '../utils/stringUtils';
import ExpiringNavLink from './ExpiringNavLink';

/*
 kind, collection, [category]

 workshop - workshops.ws
 youtube(1) - workshop.replay1     (Title Replay Type 1)
 youtube(2) - workshop.replay2     (Title Replay Type 2)

 youtube - [ { kind, collection } ]

 mapping:
  - kind, collection, [category] => page X


  / speakers / Alexis Thésée / Plénière 1 / Gregory Potdevin
  /speakers/gregory

  / Workshops / Workshop 1

*/

function computePath(entity, itemConfig = {}, screensConfig) {
  const { kind, parentPath } = entity;
  const { path, disableItemPage } = itemConfig;
  if (disableItemPage) return null;

  // console.log('path', path);
  // console.log('kind', kind);
  if (kind) {
    switch (kind) {
      case 'workshop':
        return `${path || entity.collection}/${entity.slug || entity._id}`;
      case 'workshops': {
        if (itemConfig?.workshop?.canonicalPath) {
          return replaceValues(itemConfig?.workshop?.canonicalPath, entity);
        }
        if (itemConfig?.[entity.collection]?.canonicalPath) {
          return replaceValues(itemConfig?.[entity.collection]?.canonicalPath, entity);
        }
        if (parentPath) return parentPath;
        return replaceValues(`${path || entity.collection}`, entity);

        // return `${path || entity.collection}`;
      }
      default: {
      }
      // eslint-disable-next-line no-console
      // console.warn('Unhandled entity "kind" path for', entity);
      // return null;
    }
  }
  // console.log('je passe ici?');
  if (path && typeof path === 'string') return replaceValues(path, entity);
  switch (entity.collection) {
    case 'speakers':
      return `/speakers/${entity.slug || entity._id}`;
    case 'sponsors':
      return `/sponsors/${entity.slug || entity._id}`;
    case 'program': {
      const canonicalPath = screensConfig?.programs?.program?.canonicalPath;
      if (canonicalPath) return replaceValues(canonicalPath, entity);
      return `/programs/${entity.slug || entity._id}`;
    }
    case 'workshops':
    case 'live':
    case 'atelier':
    case 'ateliers': {
      const { workshopPath = '/schedule/{category}/{slug}' } = itemConfig.workshops || {};
      return replaceValues(workshopPath, entity);
    }
    case 'workshop-live': {
      const { workshopPath = '/live/{slug}' } = itemConfig.workshops || {};
      return replaceValues(workshopPath, entity);
    }
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled entity path for', entity);
      return null;
  }
}

const EntityLink = ({ children, entity, autoHide, ...props }) => {
  // TODO: introduce entity.kind/type ?
  const { screens } = useConfig();
  const itemConfig = useScreenConfig(entity.kind === 'workshops' ? 'workshops' : entity.collection);
  const path = computePath(entity, itemConfig, screens);
  // console.log('path', path);
  // console.log('itemConfig', itemConfig);
  if (!path) {
    if (autoHide) return null; // Hide link
    return <div {...props}>{children}</div>; // Default to div
  }
  return (
    <ExpiringNavLink {...props} to={path}>
      {children}
    </ExpiringNavLink>
  );
};

EntityLink.defaultProps = {
  autoHide: false,
};

EntityLink.propTypes = {
  autoHide: PropTypes.bool, // If used as a link and not a component, auto-hide link
  children: PropTypes.node.isRequired,
  entity: PropTypes.object.isRequired,
};

export default EntityLink;
