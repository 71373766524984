import PropTypes from 'prop-types';
import React from 'react';
import { useSpeakerIds } from '../../core/users/users.hooks';
import Avatars from '../Avatars';

const SpeakerAvatars = ({ speakerIds, size }) => {
  const speakers = useSpeakerIds(speakerIds);
  if (!speakers || speakers.length === 0) return null;
  return <Avatars users={speakers} size={size} imageKey="thumbnail" />;
};

SpeakerAvatars.defaultProps = {
  size: undefined,
  speakerIds: undefined,
};
SpeakerAvatars.propTypes = {
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
  speakerIds: PropTypes.arrayOf(PropTypes.string),
};

export default SpeakerAvatars;
