import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BlockContainer from '../../components/BlockContainer';
import VideoEmbed from '../../components/VideoEmbed';
import { useScreenConfig } from '../../config/screens.context';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';

import { getString } from '../../utils';

import Poster from '../components/Poster';
import { containerTypePropTypes } from '../../propTypes';
import VideoTracker from '../../core/trackers/VideoTracker';

const translationPrefix = 'workshops.workshop';

const WorkshopLiveBlock = ({
  allowFullScreen,
  containerType,
  field,
  item,
  registration,
  videoProps,
  replayField,
  title,
  preLiveImage,
  postLiveImage,
}) => {
  const { mandatory } = item;
  const videos = item[field] || [];

  const { trackEvent } = useTracking();
  const { workshop = {} } = useScreenConfig('workshops');
  const { liveAvailableOnlyForRegistered = false, countdownMode = 'default' } = workshop;

  function onViewLive() {
    trackEvent(eventTags.WORKSHOP_VIEW_LIVE, {
      userId: store.user._id,
      workshop: item,
    });
  }
  function onClickLive() {
    trackEvent(eventTags.WORKSHOP_CLICK_LIVE, {
      userId: store.user._id,
      workshop: item,
    });
  }

  // Track on mount ?
  const autoHide = liveAvailableOnlyForRegistered && !registration && !mandatory;
  const hasVideos = !autoHide && videos && videos.length > 0;
  const hasReplays = item[replayField]?.length;
  useEffect(() => {
    if (hasVideos) {
      onViewLive();
    }
  }, [hasVideos, item._id]);

  if (autoHide) {
    return null;
  }
  if (!hasVideos && !hasReplays) {
    return (
      <Poster
        countdownMode={countdownMode}
        item={item}
        header={title || getString(`${translationPrefix}.live-streaming`)}
        className="block--video"
        containerType={containerType}
        postLiveImage={item.postLiveImage?.image ? item.postLiveImage : postLiveImage}
        preLiveImage={item.preLiveImage?.image ? item.preLiveImage : preLiveImage}
      />
    );
  }
  if (!hasVideos) return null;

  const video = videos[0];

  return (
    <BlockContainer
      className="block--video"
      type={containerType}
      header={title || getString(`${translationPrefix}.live-streaming`)}
    >
      <VideoTracker item={item} video={video} />
      <VideoEmbed
        icon="play"
        defaultActive
        allowFullScreen={allowFullScreen}
        iframe={{
          allowFullScreen,
        }}
        {...videoProps}
        {...video}
        url={video.uri}
        onClick={onClickLive}
      />
    </BlockContainer>
  );
};

WorkshopLiveBlock.defaultProps = {
  allowFullScreen: true,
  containerType: 'segment',
  field: 'liveStreams',
  replayField: 'replays',
  title: undefined,
  registration: undefined,
  videoProps: {},
  preLiveImage: undefined,
  postLiveImage: undefined,
};
WorkshopLiveBlock.propTypes = {
  allowFullScreen: PropTypes.bool,
  containerType: containerTypePropTypes,
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  registration: PropTypes.object,
  replayField: PropTypes.string,
  title: PropTypes.string,
  preLiveImage: PropTypes.object,
  postLiveImage: PropTypes.object,
  videoProps: PropTypes.shape({
    defaultActive: PropTypes.bool,
  }),
};

const ConnectedWorkshopLiveBlock = connect((state, props) => ({
  registrations: state.registrations,
  registration: state.registrations.registrationsById[props.item._id],
}))(WorkshopLiveBlock);

export default ConnectedWorkshopLiveBlock;
