/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { getString } from '../../utils';
import VideoEmbed from '../VideoEmbed';
import './VideoModal.scss';

const VideoModal = (props) => {
  const { autoPlay, controls, onClose, video } = props;
  const { title, file, value } = video;
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent(eventTags.VIDEO_VIEW, { video });
  }, []);

  function onDownload(e) {
    e.stopPropagation();
    // TODO: track download for stats :p
    trackEvent(eventTags.VIDEO_DOWNLOAD, { video });
  }

  return (
    <Modal className="VideoModal" basic closeIcon onClose={onClose} open>
      <Modal.Content>
        {file && file.uri ? (
          <video
            className="VideoModal--Video"
            autoPlay={autoPlay}
            controls={controls}
            src={file.uri}
          />
        ) : (
          <VideoEmbed
            autoPlay={autoPlay}
            className="VideoModal--Video"
            defaultActive
            iframe={{
              allowFullScreen: true,
            }}
            url={value.uri}
            {...video}
          />
        )}
      </Modal.Content>
      {title && (
        <Modal.Header className="VideoModal--Header">
          {title}
          {value?.downloadUri && (
            <Button
              floated="right"
              color="blue"
              as="a"
              target="_blank"
              onClick={onDownload}
              href={value.downloadUri}
            >
              {getString('btn.download')}
            </Button>
          )}
        </Modal.Header>
      )}
    </Modal>
  );
};
VideoModal.defaultProps = {
  autoPlay: true,
  controls: true,
};
VideoModal.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  video: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default VideoModal;
