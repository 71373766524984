import React, { useState } from 'react';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';
import { useConfig } from '../../../config/config.context';
import OptLink from '../../../OptLink';
import { motions } from '../../../utils/framerMotion';
import './AccordionFooter.scss';

const Point = () => <span className="point">•</span>;

export const ExternalLink = ({ to, children }) => (
  <a href={to} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const Link = ({ type, to, children, ...props }) => {
  if (!to || (type && !type.includes('link'))) return null;
  const Component = type === 'internal-link' ? OptLink : ExternalLink;
  return (
    <Component to={to} {...props}>
      <Point />
      {children}
    </Component>
  );
};

const String = ({ title }) => (
  <>
    <Point /> {title}
  </>
);

const components = {
  'internal-link': Link,
  'external-link': Link,
  string: String,
};

const Item = (props) => {
  const { title, type } = props;
  const Component = components[type] || 'string';
  return (
    <div className="item">
      <Component {...props}>{title}</Component>
    </div>
  );
};

const variants = {
  open: { opacity: 1, y: '0' },
  closed: { opacity: 0, y: '-100' },
};

function AccordionFooter() {
  const { footer } = useConfig();
  const [isHidden, setIsHidden] = useState(false);
  if (!footer) return null;
  const { icon, title, items = [], styles = {} } = footer;
  const { backgroundColor = 'transparant', color } = styles;
  // if (isHidden)
  return (
    <>
      <div
        className="accordion-footer close"
        style={{ backgroundColor, color }}
        onClick={() => setIsHidden(!isHidden)}
      >
        <Icon name="chevron up" className="close" />
      </div>
      <motions.div
        variants={variants}
        className={cx('accordion-footer', !isHidden ? 'open' : 'closed')}
      >
        <div className="trapezoid" style={{ backgroundColor, color }}>
          <Icon name="times" className="close" onClick={() => setIsHidden(!isHidden)} />
          {icon && <Icon name={icon} style={{ marginRight: 10 }} />}
          <p className="title">{title}</p>
        </div>
        <div className="items" style={{ backgroundColor, color }}>
          <div className="items__wrapper">
            <div className="items__wrapper__100vw">
              {items.map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </div>
            <div className="items__wrapper__100vw">
              {items.map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </motions.div>
    </>
  );
}

export default AccordionFooter;
